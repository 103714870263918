@import '../../styles/vars.css';

.imagePreloader {
  width: 100%;
  height: 100% !important;
  min-height: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  background-color: var(--base-light-color);

  /* background: linear-gradient(90deg, var(--base-light-color), #dedede); */
  background-size: 400% 400%;
  animation: gradientAnimation 3s ease infinite;

}

/* @keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */
