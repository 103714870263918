@import '../../styles/vars.css'; 

.about {
  padding: 40px 0;

  font-family: var(--ff-Tilda);
  color: var(--base-dark-color);
  line-height: 1.1;

  background-color: var(--base-light-color);
}

.about__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.about__headerTitle {
  margin: 0;
  margin-bottom: 15px;

  font-size: var(--fz-m);
}

.about__headerSubtitle {
  margin: 0;

  font-size: calc(var(--fz-s) + 1px);
}

@media (min-width: 500px) {
  .about__headerSubtitle {
    font-size: calc(var(--fz-s) + 2px);
  }
}

@media (min-width: 768px) {

  .about__headerTitle {
    margin-bottom: 20px;

    font-size: calc(var(--fz-m) + 2px);
  }

  .about__headerSubtitle {
    font-size: var(--fz-m);
  }
}

@media (min-width: 1024px) {
  .about__container {
    display: grid;
    grid-template-columns: 1fr minmax(400px, auto);
    gap: 40px;
  }

  .about {
    padding: 80px 0;
  }
}
