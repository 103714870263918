@import '../../styles/vars.css'; 

.contacts {
  padding: 30px 0;

  font-family: var(--ff-Tilda);
  background-color: var(--base-light-color);
  color: var(--base-dark-color);
}

.contacts__header {
  margin: 0;
  margin-bottom: 20px;

  font-size: var(--fz-m);
  font-weight: 500;
  text-align: center;
}

.contacts__innerContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contacts__infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contacts__itemWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contacts__subtitle {
  margin: 0;

  font-size: var(--fz-s);
  font-weight: 500;
}

.contacts__item {
  display: flex;
  align-items: stretch;
  gap: 10px;

  font-size: var(--fz-s);
}

.contacts__item * {
  margin: 0;

  color: var(--base-dark-color);
  line-height: 1;
}

.contacts__map {
  display: flex;
  align-items: center;
  justify-content: center;

  & .map {
    width: 100% !important;
    max-width: 600px;
    
    aspect-ratio: 1 / 1;
  }

  & img {
    width: 100%;
    max-width: 600px;
    
    aspect-ratio: 1 / 1;
  }
}

.socialsWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  & a {
    font-size: var(--fz-s);

    &:hover {
      background-color: transparent;
    }
  }
}

.feedbackButton {
  padding: 5px 20px;

  width: fit-content;
  height: auto;

  display: flex;
  align-items: center;

  font-size: var(--fz-s);
  color: var(--base-light-color);
  font-family: var(--ff-Tilda);
  
  border: none;
  border-radius: 20px;
  background-color: var(--color-orange);
  box-shadow: 0 2px 5px -2px var(--base-dark-color);

  &:hover {
    color: var(--base-light-color) !important;
    background-color: var(--color-red) !important;
  }
}

@media (min-width: 768px) {
  .contacts__header {
    margin-bottom: 40px;
  }

  .contacts__innerContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .contacts__infoWrapper {
    gap: 30px;
  }

  .contacts__subtitle {
    font-size: calc(var(--fz-m) - 2px);
    font-weight: 600;
  }

  .contacts__item * {
    font-size: calc(var(--fz-m) - 2px);
  }
  .contacts__map {

    & .map {
      max-width: 500px;
    }
    
    & img {
      max-width: 500px;
    }
  }
}

@media (min-width: 1024px) {
  .contacts {
    padding: 40px 0;
  }
  .contacts__header {
    font-weight: 600;
  }
}

@media (min-width: 1260px) {
  .feedback__button {
    font-size: calc(var(--fz-m) - 2px);
  }
}
