@import '../../styles/vars.css';

.gallery__slider {
  transition: all 300ms ease-in-out;
  overflow: visible !important;
}

@media (min-width: 1260px) {
  .gallery__slider {
    margin-bottom: 70px;
  }
}

.gallery__slide {
  transition: all 300ms ease-in-out;
  opacity: 0.5;

  &.swiper-slide-active {
    transition: all 200ms ease-in-out;
    transition-delay: 0.1s;
    opacity: 1;
    scale: 1.8;
  }
}

/* Кнопки */
.gallery__slider .swiper-button-prev,
.gallery__slider .swiper-button-next {
  display: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--color-red);

  &::before,
  &::after {
    font-size: 16px;
    font-weight: 600;
    color: var(--base-light-color);
  }
}

@media (min-width: 1260px) {
  .gallery__slider .swiper-button-prev,
  .gallery__slider .swiper-button-next {
    display: flex;
    align-items: center;
  }

  .gallery__slide {
    &.swiper-slide-active {
      scale: 1.5;
    }
  }
}

.gallery__slider .swiper-button-prev {
  right: auto;
  left: 27.8%;
}

.gallery__slider .swiper-button-next {
  right: 27.8%;
  left: auto;
}
