@import '../../styles/vars.css';

.hero {
  font-family: var(--ff-Tilda);
  
  background-color: var(--base-light-color);
}

.hero__container {
  display: grid;
  grid-template-rows: 2fr 1fr;
  box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.5);
}

.hero__imageWrapper {
  order: 0;

  width: 100%;
  height: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  z-index: 10;
  
  & div {
    width: 100% !important;
    height: 100% !important;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  & img {
    height: 100% !important;

    object-fit: cover;
    aspect-ratio: 2/1;
  }
}

.hero__textWrapper {
  order: 1;

  padding: 20px;
  margin: 0 auto;
  
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  text-align: center;

  background-color: var(--color-orange);
  background: var(--secondary-gradient);
  background-image: url("../../assets/svg_bg.svg");
  background-size: 350%;
  background-repeat: no-repeat;
  background-position: center;
  /* background-attachment: fixed; */
}

.hero__text {
  margin: 0;

  font-weight: 500;
  font-size: calc(var(--fz-m) - 3px);
  line-height: 1.2;
  color: var(--base-light-color);
  user-select: none;
}

@media (min-width: 500px) {

  .hero__container {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    align-items: center;
    grid-template-rows: auto;
  }
  
  .hero__textWrapper {
    order: 0;

    width: 100%;
    height: 100%;
  }
}

@media (min-width: 768px) {

  .hero__text {
    margin: 0;
  
    font-weight: 500;
    line-height: 1.2;
  }
}

@media (min-width: 1024px) {

  .hero__container {
    grid-template-columns: 1fr 2fr;
    align-items: center;
  }

  .hero__textWrapper {
    height: 100%;
  }

  .hero__text {
    font-size: var(--fz-l);
  }
}

@media (min-width: 1260px) {
  .hero {
    padding-bottom: 60px;
  }

  .hero__container {
    padding: 0 !important;
  }

  .hero__imageWrapper {
    box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.5);
  }
}
