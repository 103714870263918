@import '../../styles/vars.css';

.socialLink {
  padding: 5px;

  display: flex;
  align-items: center;
  gap: 5px;

  color: var(--base-dark-color);
  font-size: var(--fz-s);

  border-radius: 5px;
  transition: all 250ms ease-in-out;
  
  & svg {
    padding: 3px;

    width: 20px;
    height: 20px;
    
    display: flex;
    border-radius: 50%;
  }

  &:hover {
    background-color: var(--color-grey);
  }
}

.socialLink__telegram {
  & svg {
    background-color: var(--color-telegram-blue);
  }
}

.socialLink__whatsapp {
  & svg {
    background-color: var(--color-whatsapp-green);
  }
}

.socialLink__vk {
  & svg {
    background-color: var(--color-vk-blue);
  }
}
