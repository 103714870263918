@import '../../styles/vars.css'; 

.intro {
  padding-bottom: 40px;

  background-color: var(--base-light-color);
  font-family: var(--ff-Tilda);
  color: var(--base-dark-color);
}

.logoWrapper {
  padding: 20px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  
  & div {
    height: 100%;
    width: 100%;
    max-width: 350px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  & img {
    width: 100%;

    object-fit: cover;
  }
}

.contacts {
  padding: 10px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.phone__title {
  margin: 0;
  margin-bottom: 10px;

  display: none;

  font-size: var(--fz-m);
}

.phone__number {
  margin: 0;

  font-size: var(--fz-m);
  font-weight: 600;
  text-decoration: none;
  color: var(--base-dark-color);
}

.openingHours__text {
  margin: 0;

  font-size: calc(var(--fz-s) - 2px);
}

@media (min-width: 768px) {
  .intro {
    padding: 40px 0;
  }

  .intro__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .contacts {
    text-align: right;
    align-items: flex-end;
  }

  .phone__title {
    display: block;
  }
}

@media (min-width: 1260px) {

.logoWrapper {
  & div {
    min-width: 350px;
  }
}

  .contacts {
    gap: 10px;
  }

  .phone__title {
    margin-bottom: 20px;
  }

  .phone__number {
    font-size: var(--fz-l);
  }

  .openingHours__text {
    font-size: var(--fz-s);
  }
}
