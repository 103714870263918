@import '../../styles/vars.css'; 

.callMeBack__button {
  font-size: var(--fz-s);
}

.callMeBack__modal {
  width: 100%;
  max-width: 500px;

  font-family: var(--ff-Tilda);
}

.callMeBack__wrapper {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.callMeBack__submitCover {
  height: 100%;
  min-height: 151px;
  text-align: center;
  
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & h2 {
    font-weight: 500;
  }
}

.callMeBack__form {
  padding: 10px;
  width: 100%;
  max-width: 350px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-self: center;

  & div {
    align-items: center;
  }
  
  & label {
    font-family: var(--ff-Tilda);
  }

  & input {
    padding: 10px;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    font-size: var(--fz-s);
    line-height: 1;
    border-radius: 6px;
    outline: none;
    box-shadow: 0 0 0 1px var(--color-grey);
    border: none;
    
    &::placeholder {
      color: var(--color-grey);
    }
  }
  
  & button {
    margin-bottom: 0;
    padding: 13px 30px;

    width: 100%;
    
    font-size: var(--fz-s);
    font-family: var(--ff-Tilda);
    font-weight: 500;
    
    border: none;
    cursor: pointer;
    background-color: var(--color-red);
    color: var(--base-light-color);
    transition: all 0.2s ease-in-out;
  
    &:hover {
      background-color: var(--color-red);
      color: var(--base-light-color);
    }

    &:disabled {
      pointer-events: none;
      user-select: none;
      opacity: 0.5;
    }

    @media (min-width: 1260px) {
      font-size: calc(var(--fz-m) - 3px);
    }
  }
}
